<template>
  <div class="main-view">
    <div class="section">
      <div class="filter__wrap">
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item prop="keyword" >
            <el-input v-model="table.params.keyword" placeholder="产品名称 / 下单客户姓名/电话" style="width:240px;">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="make_time">
            <el-date-picker
              v-model="table.params.make_time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="onSearch">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-radio-group class="radio_group_wrap" v-model="table.params.status" @change="handleTabs" size="medium">
          <el-radio-button :label="1">待支付({{table.paying}})</el-radio-button>
          <el-radio-button :label="2">待核销({{table.verifying}})</el-radio-button>
          <el-radio-button :label="3">已核销({{table.finish}})</el-radio-button>
          <el-radio-button :label="4">已退款({{table.refund}})</el-radio-button>
          <el-radio-button :label="5">已取消({{table.cancel}})</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="curField"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:goods_title="{row}">
        <div class="flex" style="align-items:center;">
          <el-image
            style="width: 80px; height: 40px;border-radius:2px;margin-right:10px"
            :src="row.goods_image"
            fit="cover"
            :preview-src-list="[row.goods_image]"></el-image>
            <div class="table-col-text">{{row.goods_title}}</div>
        </div>
      </template>
      <template v-slot:status="{row}">
        <!-- 待支付状态列表不判断 '全部退款' 或者 '部分退款' -->
        <span class="color1" v-if="row.status == 1">
          待支付
        </span>
        <!-- pay_num:报名人数, refund_num 若退款人数大于0则判断是'全部退款' 或者 '部分退款'，否则状态展示本身状态 -->
        <span v-else :class="`corlor${row.status}`">
          {{
            row.refund_num > 0 ? ( row.pay_num == row.refund_num ? '全部退款' : '部分退款') : toStr({1:'待支付',2:'待核销',3:'已完成',4:'已取消'},row.status)
          }}
        </span>
      </template>
      <template v-slot:amount="{row}">
        <span>{{row.amount}}元/人</span>
      </template>
      <template v-slot:income_amount="{row}">
        <span style="color:#FD4100;font-weight: bold;">{{row.income_amount}}</span>
      </template>
      <template v-slot:action="{row}">
        <!-- 1:待支付 2:待核销 3:已完成 4:已退款 5:已取消 默认为待支付  -->
        <template v-if="table.params.status == 2">
          <el-button type="text" @click='showEdit(row)'>核销</el-button>
          <span class="action-btn-line">|</span>
        </template>
        <el-button type="text" @click='showDetail(row)'>详情</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
    <detail ref="detail" @refresh="getTable"></detail>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'AccountList',
  components: {
    VTable,
    Edit,
    Detail
  },
  mixins: [mixinTable],
  data() {
    return {
      field1: [
        { name: "goods_title", label: "产品信息", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "school_name", label: "下单客户", hidden: false},
        { name: "apply_num", label: "报名人数(人)", hidden: false},
        { name: "total_amount", label: "订单金额(元)", hidden: false},
        { name: "income_amount", label: "收益金额(元)", hidden: false},
        { name: "status", label: "订单状态", hidden: false},
        { name: "make_time", label: "预约日期", hidden: false},
        { name: "action", label: "操作", width: "110", hidden: false }
      ],
      field2: [
        { name: "goods_title", label: "产品信息", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "school_name", label: "下单客户", hidden: false},
        { name: "apply_num", label: "报名人数(人)", hidden: false},
        { name: "total_amount", label: "订单金额(元)", hidden: false},
        { name: "income_amount", label: "收益金额(元)", hidden: false},
        { name: "status", label: "订单状态", hidden: false},
        { name: "make_time", label: "预约日期", hidden: false},
        { name: "pay_time", label: "支付时间", hidden: false},
        { name: "action", label: "操作", width: "110", hidden: false }
      ],
      field3: [
        { name: "goods_title", label: "产品信息", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "school_name", label: "下单客户", hidden: false},
        { name: "apply_num", label: "报名人数(人)", hidden: false},
        { name: "total_amount", label: "订单金额(元)", hidden: false},
        { name: "income_amount", label: "收益金额(元)", hidden: false},
        { name: "status", label: "订单状态", hidden: false},
        { name: "make_time", label: "预约日期", hidden: false},
        { name: "verify_time", label: "核销时间", hidden: false},
        { name: "action", label: "操作", width: "110", hidden: false }
      ],
      field4: [
        { name: "goods_title", label: "产品信息", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "school_name", label: "下单客户", hidden: false},
        { name: "apply_num", label: "报名人数(人)", hidden: false},
        { name: "total_amount", label: "订单金额(元)", hidden: false},
        { name: "income_amount", label: "收益金额(元)", hidden: false},
        { name: "status", label: "订单状态", hidden: false},
        { name: "refund_num", label: "退款人数", hidden: false},
        { name: "make_time", label: "预约日期", hidden: false},
        { name: "refund_time", label: "退款时间", hidden: false},
        { name: "action", label: "操作", width: "110", hidden: false }
      ],
      field5: [
        { name: "goods_title", label: "产品信息", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "school_name", label: "下单客户", hidden: false},
        { name: "apply_num", label: "报名人数(人)", hidden: false},
        { name: "total_amount", label: "订单金额(元)", hidden: false},
        { name: "income_amount", label: "收益金额(元)", hidden: false},
        { name: "status", label: "订单状态", hidden: false},
        { name: "make_time", label: "预约日期", hidden: false},
        { name: "cancel_time", label: "取消时间", hidden: false},
        { name: "action", label: "操作", width: "110", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          make_time: [],
          make_time_start: '',
          make_time_end: '',
          status: 1, // 筛选状态 1:待支付 2:待核销 3:已完成 4:已退款 5:已取消 默认为待支付
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        paying: 0, //待支付总数
        verifying: 0, //待核销总数
        finish: 0, //已完成总数
        refund: 0, //已退款总数
        cancel: 0 //已取消总数
      },
      curPowerArr: [],
      curField: '',
    }
  },
  created() {
    this.getTable();
    this.curField = this.field1;
  },
  methods: {
    handleTabs(value) {
      if(value == 1) {
        this.curField = this.field1
      } else if(value == 2) {
        this.curField = this.field2
      } else if(value == 3) {
        this.curField = this.field3
      } else if(value == 4) {
        this.curField = this.field4
      } else if(value == 5) {
        this.curField = this.field5
      }
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 查询列表
    getTable() {
      let _params = {...this.table.params}
      let make_time = this.table.params.make_time;
      if(make_time.length > 1) {
        _params.make_time_start = make_time[0];
        _params.make_time_end = make_time[1];
      } else {
        _params.make_time_start = '';
        _params.make_time_end = '';
      }
      delete _params.make_time
      this.$http.get('/company/order/list', {params: _params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
          this.table.paying = res.data.paying;
          this.table.verifying = res.data.verifying;
          this.table.finish = res.data.finish;
          this.table.refund = res.data.refund;
          this.table.cancel = res.data.cancel;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row,this.table.params.status)
      dom = null
    },
  }
}
</script>

<style scoped lang="scss">
.radio_group_wrap {
  margin:0 18px 18px;

  &::v-deep {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #0376E7;
      background-color: #E5F2FE;
      border-color: #0376E7;
    }
  }
}
.corlor1 {
  display: flex;
  align-items: center;
}
.corlor2 {
  display: flex;
  align-items: center;
}
.corlor3 {
  display: flex;
  align-items: center;
}
.corlor1::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #FFA10E;
  display: inline-block;
  margin-right: 5px;
}
.corlor2::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #27D144;
  display: inline-block;
  margin-right: 5px;
}
.corlor3::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #EE5050;
  display: inline-block;
  margin-right: 5px;
}
.text1 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.corlor1 {
  display: flex;
  align-items: center;
}
.corlor2 {
  display: flex;
  align-items: center;
}
.corlor3 {
  display: flex;
  align-items: center;
}
.corlor4 {
  display: flex;
  align-items: center;
}
.corlor1::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #FFA10E;
  display: inline-block;
  margin-right: 5px;
}
.corlor2::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #FFA10E;
  display: inline-block;
  margin-right: 5px;
}
.corlor::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #27D144;
  display: inline-block;
  margin-right: 5px;
}
.corlor4::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #EE5050;
  display: inline-block;
  margin-right: 5px;
}
</style>
