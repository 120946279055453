<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="toggle(false)">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.parent }}
          <span> > </span>
          {{ $route.meta.title }}
          <span> > </span>
          订单详情
        </div>
      </div>
    </el-header>

    <div class="order-info">
      <div class="flex" style="justify-content:space-between">
        <div class="flex">
          <img style="width:20px;height:20px;margin-right:5px;" :src="require('@/assets/img/order-icon.png')" />
          订单编号：{{detailInfo.order_no}}
        </div>
        <!-- 待支付可以修改预约日期 -->
        <el-button size="mini" @click="handleTime" v-if="detailInfo.status == 1">修改预约日期</el-button>
        <!-- 待核销有核销操作 -->
        <el-button size="mini" @click="showEdit" v-if="detailInfo.status == 2">核销</el-button>
      </div>
      <div class="flex" style="justify-content:space-between;margin-top:20px;padding:0 20px;">
        <el-row>
          <el-col :span="12" class="mb20">
            <span class="label">下单客户：</span>
            <span>{{detailInfo.school_name}}</span>
          </el-col>
          <el-col :span="12" class="mb20">
            <span class="label">报名人数：</span>
            <span>{{detailInfo.apply_num}}人</span>
          </el-col>
          <el-col :span="12" class="mb20">
            <span class="label">预约日期：</span>
            <span>{{detailInfo.make_time}}</span>
          </el-col>
          <el-col :span="12" class="mb20">
            <span class="label">报名时间：</span>
            <span>{{detailInfo.create_time}}</span>
          </el-col>
          <el-col :span="12" v-if="detailInfo.status == 2">
            <span class="label">支付时间：</span>
            <span>{{detailInfo.pay_time}}</span>
          </el-col>
          <el-col :span="12" v-if="detailInfo.status == 3">
            <span class="label">核销时间：</span>
            <span>{{detailInfo.verify_time}}</span>
          </el-col>
          <el-col :span="12" v-if="detailInfo.status == 4">
            <span class="label">取消时间：</span>
            <span>{{detailInfo.cancel_time}}</span>
          </el-col>
        </el-row>
        <el-row style="width:300px;">
          <el-col :span="8" style="text-align:right;">
            <div>状态</div>
            <div :class="`color${detailInfo.status}`" style="margin-top:15px;">
              <span v-if="selStatus == 1">待支付</span>
              <span v-else-if="selStatus == 2 && detailInfo.status == 5" style="color:#FFA10E">待核销</span>
              <span v-else>{{detailInfo.refund_num > 0 ? ( detailInfo.pay_num == detailInfo.refund_num ? '全部退款' : '部分退款') : toStr({1:'待支付',2:'待核销',3:'已完成',4:'已取消',5:'待核销'},detailInfo.status)}}</span>
            </div>
          </el-col>
          <el-col :span="8" style="text-align:right;">
            <div>订单金额</div>
            <div style="font-size:16px;font-weight:bold;margin-top:15px;">￥{{detailInfo.total_amount}}</div>
          </el-col>
          <el-col :span="8" style="text-align:right;">
            <div>收益金额</div>
            <div style="color:#FD4100;font-size:16px;font-weight:bold;margin-top:15px;">￥{{detailInfo.income_amount}}</div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="form-wrap">
      <div class="steps">
        <div class="line-title">流程进度</div>
        <!-- status状态：1:待支付 2:待核销 3:已完成 4:已取消 -->
        <!-- 已经取消或全部退款展示 (待支付不展示退款信息) -->
        <el-steps :active="stepsStatus" class="erorSteps" align-center  finish-status="error" style="margin:20px 0;" v-if="(selStatus != 1 && detailInfo.refund_num > 0 && detailInfo.pay_num == detailInfo.refund_num)  || detailInfo.status == 4">
          <el-step title="">
            <template #title>
              <span style="font-size:18px;color:#333;">客户下单报名</span>
            </template>
            <template #icon>
              <span style="font-size:40px">.</span>
            </template>
            <template #description>
              <div style="font-size:16px;color:#333333;margin-top:10px;">{{detailInfo.school_name}}</div>
              <div style="color:#999;">{{detailInfo.create_time}}</div>
            </template>
          </el-step>
          <el-step title="" >
            <template #title>
              <span style="font-size:18px;">{{detailInfo.status == 4  ? '客户取消' : '全部退款'}}</span>
            </template>
            <template #description v-if="stepsStatus > 1">
              <!-- 取消时间 -->
              <div style="color:#999;" v-if="detailInfo.status == 4">{{detailInfo.cancel_time}}</div>
              <!-- 退款时间 -->
              <div style="color:#999;" v-else>{{detailInfo.refund_time}}</div>
            </template>
          </el-step>
        </el-steps>
        <!-- 其他状态展示全部进度 -->
        <el-steps :active="stepsStatus" align-center process-status="wait" style="margin:20px 0;" v-else>
          <el-step title="">
            <template #title>
              <span style="font-size:18px;">客户下单报名</span>
            </template>
            <template #description>
              <div style="font-size:16px;color:#333333;margin-top:10px;">{{detailInfo.school_name}}</div>
              <div style="color:#999;">{{detailInfo.create_time}}</div>
            </template>
          </el-step>
          <el-step title="" >
            <template #title>
              <span style="font-size:18px;">客户确认支付</span>
            </template>
            <template #description v-if="stepsStatus > 1">
              <div style="font-size:16px;color:#333333;margin-top:10px;">
                <span>￥{{detailInfo.total_amount}}</span>
                <span v-if="detailInfo.refund_num > 0 && detailInfo.pay_num != detailInfo.refund_num" style="color:#fd4100;">（部分退款）</span>
              </div>
              <div style="color:#999;">{{detailInfo.pay_time}}</div>
            </template>
          </el-step>
          <el-step title="待核销">
            <template #description v-if="stepsStatus > 1">
              <div style="font-size:16px;color:#333333;margin-top:10px;">预约日期</div>
              <div style="color:#999;">{{detailInfo.make_time}}</div>
            </template>
          </el-step>
          <el-step title="订单完成">
            <template #description v-if="stepsStatus > 2">
              <div style="font-size:16px;color:#333333;margin-top:10px;">核销时间</div>
              <div style="color:#999;">{{detailInfo.verify_time}}</div>
            </template>
          </el-step>
        </el-steps>
      </div>

      <!-- 退款信息 (待支付状态不展示退款信息) -->
      <div class="steps" v-if="tableData.length > 0 && selStatus != 1">
        <div class="line-title">退款信息<span style="color:#FD4100;">（{{detailInfo.pay_num == detailInfo.refund_num ? '全部退款' : '部分退款'}}，该款项已原路退回至支付账户）</span></div>
        <el-row style="width:800px;padding:20px 38px;">
          <el-col :span="12" class="mb20">
            <span class="label">原报名人数：</span>
            <span>{{detailInfo.apply_num}} 人</span>
          </el-col>
          <el-col :span="12" class="mb20">
            <span class="label">原订单金额：</span>
            <span>{{detailInfo.total_amount}} 元</span>
          </el-col>
          <el-col :span="12">
            <span class="label">退款人数：</span>
            <span>{{detailInfo.refund_num}} 人</span>
          </el-col>
          <el-col :span="12">
            <span class="label">退款总金额：</span>
            <span>{{detailInfo.refund_amount}} 元</span>
          </el-col>
        </el-row>
        <el-table
          :data="tableData"
          border
          size="small"
          class="refundTable"
          max-height="500px;"
          :header-cell-style="{background:'#FFECE5',color:'#FD4100 '}"
          style="width: 600px;margin:0 38px 10px">
          <el-table-column
            prop="refund_no"
            label="退款编号"
            width="180">
          </el-table-column>
          <el-table-column
            prop="amount"
            label="退款金额（元）"
            width="180">
          </el-table-column>
          <el-table-column
            prop="update_time"
            label="退款时间">
          </el-table-column>
        </el-table>
      </div>

      <div class="steps">
        <div class="line-title">下单信息</div>
        <el-row style="padding:20px 38px;">
          <el-col :span="12" class="mb20">
            <span class="label">联系人：</span>
            <span>{{detailInfo.contact}}</span>
          </el-col>
          <el-col :span="12" class="mb20">
            <span class="label">手机号码：</span>
            <span>{{detailInfo.contact_phone}}人</span>
          </el-col>
          <el-col :span="12">
            <span class="label">班级：</span>
            <span>{{detailInfo.class_name}}</span>
          </el-col>
          <el-col :span="12">
            <span class="label">备注：</span>
            <span>{{detailInfo.remark}}</span>
          </el-col>
        </el-row>
        <div class="steps" style="margin:10px 38px;border:solid 1px #eee;padding-bottom:0;"> 
          <div class="line-title">
            <span>产品信息</span>
            <span class="more" @click="showDetail">查看详情></span>
          </div>
          <el-row style="padding:20px 38px;background:#FBFBFB;">
            <el-col :span="12" class="mb20">
              <span class="label">产品标题：</span>
              <span>{{detailInfo.good_info.title}}</span>
            </el-col>
            <el-col :span="12" class="mb20">
              <span class="label">开放时间：</span>
              <span>{{detailInfo.good_info.open_time}}人</span>
            </el-col>
            <el-col :span="12" class="mb20">
              <span class="label">适用学段：</span>
               <span>{{detailInfo.good_info.period_type}}</span>
            </el-col>
            <el-col :span="12" class="mb20">
              <span class="label">活动天数：</span>
              <span>{{detailInfo.good_info.days}}</span>
            </el-col>
            <el-col :span="12" class="mb20">
              <span class="label">地址：</span>
              <span>{{detailInfo.good_info.province_str}}{{detailInfo.good_info.city_str}}{{detailInfo.good_info.area_str}}{{detailInfo.good_info.address}}</span>
            </el-col>
            <el-col :span="12" class="mb20">
              <span class="label">咨询电话：</span>
              <span>{{detailInfo.good_info.consult_mobile}}</span>
            </el-col>
            <el-col :span="12" >
              <span class="label">售价：</span>
              <span style="color:#F53030;font-weight:bold;">{{detailInfo.good_info.amount}} 元/人</span>
            </el-col>
            <el-col :span="12" >
              <span class="label">产品标签：</span>
              <span v-for="item in detailInfo.good_info.tag_ids" :key="item">{{item}}</span>
            </el-col>
          </el-row>
        </div>

      </div>
    </div>

      
    <!-- 修改预约时间 -->
    <el-dialog
      append-to-body
      width="460px"
      title=""
      :visible.sync="dialogVisible"
      :before-close="reset"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <template #title>
        <div class="el-dialog-title">修改预约时间</div>
      </template>

      <el-form
        v-loading="loading"
        class="saft_form"
        element-loading-text="加载中…"
        ref="elFormDom"
        label-width="130px"
        label-position="top"
        :model="form.data"
        :rules="form.rules"
        size="medium"
        hide-required-asterisk
      >
        <el-form-item prop="make_time" label="选择日期">
          <el-date-picker
            v-model="form.data.make_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="end">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            :loading="form.loading"
            @click="confirm"
            >确定</el-button
          >
        </el-row>
      </template>
    </el-dialog>

    <edit ref="edit" @refresh="getDetail(this.detailInfo.id)"></edit>
    <!-- 产品详情 -->
    <GoodsDetail ref="goodsdetail" ></GoodsDetail>
    

  </div>
</template>


<script>
import Edit from './Edit'
import GoodsDetail from '@/views/product/goods/components/Detail.vue'
export default {
  name: 'OrderDetail',
  components: {
    Edit,
    GoodsDetail
  },
  data() {
    return {
      loading: false,
      visible: false,
      dialogVisible: false,
      form: {
        login:false,
        data: {
          make_time: '',
        },
        rules:{
          make_time: [{required: true, message: "必填项", trigger: "change"}],
        }
      },
      detailInfo: {
        good_info:{},
        refund_orders: [],
      },
      currType: 0, // 0:创建，1:复制，2:编辑
      stepsStatus: 1,
      tableData: [],
      goodsDetail: {},
      accessArr:{1:'小学',2:'初中',3:'高中'},
      selStatus: 0,
    }
  },
  methods: {
    // 详情
    getDetail(row, selStatus) {
      this.selStatus = selStatus;
      this.tableData = [];
      this.$http.get('/company/order/detail',{ params:{ id:row.id } }).then(res => {
        if(res.code == 1) {
          this.form.data.id = res.data.id;
          this.form.data.make_time = res.data.make_time;// 预约日期
          this.detailInfo = res.data;
          if(res.data.refund_orders.length > 0) {
            this.tableData = res.data.refund_orders;
          }
          if(res.data.status == 2 ){
            this.stepsStatus = 3
          } else if(res.data.status == 3 ) {
            this.stepsStatus = 4
          } else if(selStatus == 2 && res.data.status == 5) { // 待核销并且是不分退款的，进度状态改为待核销
            this.stepsStatus = 3
          }else if(selStatus == 1 && res.data.status == 5) {// 待支付状态（不判断退款）
            this.stepsStatus = 1
          } else {
            this.stepsStatus = res.data.status
          }
        }
      })
    },
    reset(done) {
      this.isChange = false
      done && done()
    },
    toggle(show) {
      this.$emit('refresh')
      this.visible = show
      !show && this.reset()
    },
    handleTime() {
      this.dialogVisible = true;
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if (valid) {
          this.form.loading = true;
          let _params = {
            id: this.form.data.id,
            make_time: this.form.data.make_time
          }
          this.$http.post('/company/order/changeMakeTime', _params).then(res => {
            if(res.code === 1) {
              this.$message.success('操作成功！')
              this.dialogVisible = false;
            } else {
              this.$message.error(res.msg)
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    },
    showEdit() {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail({ id:this.detailInfo.id })
      dom = null
    },
    // 查看产品详情
    showDetail() {
      let dom = this.$refs.goodsdetail
      dom.toggle(true)
      dom.getDetail(this.detailInfo.good_info)
      dom = null
    },
    toStr(data,key){
      return data[key]
    },
    setarr(arr) {
      if(arr.length == 1) {
        return this.accessArr[arr[0]]
      } else if (arr.length == 2) {
        return this.accessArr[arr[0]] +' ， '+ this.accessArr[arr[1]]
      } else if (arr.length == 3) {
        return this.accessArr[arr[0]] +' ， '+ this.accessArr[arr[1]] +' ， '+ this.accessArr[arr[2]]
      }
    },
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #F1F4FC;
  z-index: 5 !important;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.page-header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  color: #595959;
  border-bottom: solid 1px #eee;

  

  .site {
    color: #898989;
  }
  
  .backBtn {
    height: 30px;
    border: solid 1px #007DF2;
    line-height: 30px;
    padding: 0 9px;
    border-radius: 3px;
    background: #E5F2FE;
    color: #007DF2;
    cursor: pointer;
    margin: 15px 20px 15px 0;
  }
  .back-img {
    width: 18px;
    height: 16px;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
}
.title {
  height: 58px;
  line-height: 58px;
}
.order-info {
  padding: 20px;
  background: #fff;
}

.label {
  color: #999999;
}
.mb20 {
  margin-bottom: 20px;
}
.steps {
  background: #fff;
  border-radius: 5px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.line-title {
  height: 50px;
  line-height: 50px;
  border-bottom: solid 1px #eee;
  padding: 0 24px;
}
.form-wrap {
  margin: 15px 20px;
  border-radius: 5px;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.color1 { color:#FFA10E;}
.color2 { color:#FFA10E;}
.color3 { color:#27D144;}
.color4 { color:#FD4100;}
.color4 { color:#FFA10E;}

.refundTable ::v-deep .has-gutter .cell{
  color: #FD4100;
}
.more {
  color:#007DF2;
  float: right;
  cursor: pointer;
}
.erorSteps ::v-deep .el-step:first-child .el-icon-close:before {
  content: '',
}
.erorSteps ::v-deep .el-step:nth-child(2) .el-icon-close:before {
  content: '',
}
.erorSteps ::v-deep .el-step:nth-child(2) .el-step__icon.is-text {
  background:#F56C6C;
}
.erorSteps ::v-deep .el-step__icon {
  width:18px;
  height:18px;
}
</style>